import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import Botao from '../../../componentes/Botao';

const Feedback = forwardRef(({ isErro, imageSrc, tag, info, onButtonClick }, ref) => {
  return (
    <motion.div
      className="fixed inset-0 flex flex-col items-center lg:justify-center z-50 px-4 lg:px-20"
      initial={{ opacity: 0, pointerEvents: 'none' }}
      ref={ref} // Passando a ref para o motion.div
    >
      {/* Container da imagem redonda */}
      <div
        className={`relative overflow-hidden flex-shrink-0 border-8 z-10 w-48 h-48 mt-10 lg:w-60 lg:h-60 lg:max-w-none lg:max-h-none box-content bg-white border-gradienteVerdeEnd rounded-full lg:-mb-4`}
      >
        <img 
          src={imageSrc}
          alt="Feedback"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Card com textos e botão */}
      <div className="bg-white rounded-3xl shadow-cardExterno relative container">
        <div className="h-full rounded-3xl shadow-cardInterno p-10 relative flex flex-col justify-center">
          <h1 className="text-lunar font-exo2 text-2xl lg:text-4xl font-semibold mb-2 text-center">{ isErro ? "#precisa evoluir" : "#orgulho do padre:"}</h1>
          
          <p className="w-auto uppercase font-exo2 text-lg lg:text-2xl font-bold text-white px-4 py-1 lg:px-8 lg:py-2 text-center bg-gradient-to-b from-gradienteTagStart to-gradienteTagEnd mb-4 rounded-full mx-auto">
            {tag}
          </p>
          
          <p className="text-lunar font-exo2 text-lg lg:text-2xl font-semibold mb-8 text-center leading-5 lg:leading-7">{info}</p>
          
          {/* Botão de ação */}
          <Botao texto="Continuar" className=" mx-auto" onClick={onButtonClick} />
        </div>
      </div>
    </motion.div>
  );
});

export default Feedback;
