import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';// Hybrid (17kb)
import CardEnunciado from '../../componentes/Jogo/CardEnunciado';
import CardAlternativa from '../../componentes/Jogo/CardAlternativa';
import Feedback from '../../componentes/Jogo/Feedback';
import Pontuacao from '../../componentes/Jogo/Pontuacao';
import useStore from '../../store/useStore';
import arrowCreate, { DIRECTION } from 'arrows-svg';
import { motion, animate } from "framer-motion"
import { useNavigate } from 'react-router-dom'; // Importa o useNavigate
import { isEmptyOrWhitespace } from '../../functions';

const Jogo = () => {
  // const { email, isTotem } = useStore();
  const { email, perguntasSessao, setAcertos, acertos } = useStore();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [questaoAtual, setQuestaoAtual] = useState({});
  const [exibirFeeback, setExibirFeedback] = useState(null); // Estado para feedback
  const [isInteracaoTravada, setIsInteracaoTravada] = useState(false); // Estado para feedback
  const [resultado, setResultado] = useState(null);
  const [indiceAtual, setIndiceAtual] = useState(0);

  const alternativa1Ref = useRef(null);
  const alternativa2Ref = useRef(null);
  const enunciadoRef = useRef(null);
  const arrowRef = useRef(null);
  const conteudoRef = useRef(null);
  const feedbackRef = useRef(null);
  const fundoRef = useRef(null);
  
  const navigate = useNavigate();

//#region Validação da sessão
useEffect(() => {
  if(perguntasSessao == null) {
     voltarAoInicio()
     return
  }
  if(perguntasSessao.length == 0) {
     voltarAoInicio()
     return
  }
  if(isEmptyOrWhitespace(email)) {
     voltarAoInicio()
     return
  }

  setQuestaoAtual({
    ...perguntasSessao[indiceAtual],
    alternativas: embaralharAlternativas(perguntasSessao[indiceAtual].alternativas)
  });
}, []);

const voltarAoInicio = () => {
  console.log("Você deve se cadastrar antes")
  navigate(`/`);
}
//#endregion

//#region Seta
  const exibirSeta = (fromRef, toRef) => {
    esconderSeta()

    arrowRef.current = arrowCreate({
      from: {
        direction: DIRECTION.BOTTOM,
        node: fromRef.current,
      },
      to: {
        direction: DIRECTION.TOP,
        node: toRef.current,
      },
    });

    conteudoRef.current.appendChild(arrowRef.current.node);
  };

  const esconderSeta = () => {
    if (arrowRef.current) {
      arrowRef.current.clear();
    }
  }
//#endregion

//#region Drag'n'drop
  const onDrop = (dragRef) => {
    console.log("-------- ON DROP --------")
    var foiSoltoDentro = isDroppedOn(enunciadoRef, dragRef)
    if(foiSoltoDentro) {
      setIsInteracaoTravada(true)
      verificarResposta(dragRef)
      console.log(`-- Foi solto na posição? ${foiSoltoDentro}`)
      setPosition({ x: 0, y: 0 });
      setTimeout(() => {
        exibirSeta(enunciadoRef, dragRef);
      }, 200);
    }
  };
//#endregion

//#region Questões
  const verificarResposta = (alternativaRef) => {
    const alternativaSelecionada = questaoAtual.alternativas.find(alternativa => alternativa.id == alternativaRef.current.id);
  
    console.log(`Alternativa selecionada: ${alternativaRef}`)
    console.log(alternativaSelecionada)
    setExibirFeedback(true)
    setAcertos(acertos + (alternativaSelecionada.isCorreta ? 1 : 0))
    setResultado(alternativaSelecionada.isCorreta);
  };

  const abrirProximaPergunta = () => {
    if (indiceAtual < perguntasSessao.length - 1) esconderFeedback()
    else navigate("/resultadoFinal"); // Se for a última pergunta, redireciona para a tela de resultado
  }

  const esconderFeedback = () => {
    console.log("Esconder feedback antes de resetar estados")
    esconderSeta()
    
    animate(feedbackRef.current, { opacity: 0, pointerEvents: 'none' });
    animate(fundoRef.current, { background: "#ffffff00" });
    animate(conteudoRef.current, { opacity: 1 }, { delay: 0.8 });
    
    setTimeout(() => {
      setIsInteracaoTravada(false)
      resetarEstado(); // Reseta estados como posição e feedback
      setIndiceAtual(indiceAtual + 1); // Atualiza o índice da pergunta atual
      setQuestaoAtual(perguntasSessao[indiceAtual + 1]); // Atualiza a questão atual
    }, 500)
  }
  
  const resetarEstado = () => {
    console.log("Resetando estados")
    setExibirFeedback(false);
    setResultado(null);
    setPosition({ x: 0, y: 0 });
  };

  const embaralharAlternativas = (alternativas) => {
    return alternativas.sort(() => Math.random() - 0.5);
  };
//#endregion

//#region Funções de verificação da posição do card arrastado
const isDroppedOn = (targetRef, draggedRef) => {
  const target = targetRef.current.getBoundingClientRect();
  const dragged = draggedRef.current.getBoundingClientRect();

  return (
    dragged.left < target.right &&
    dragged.right > target.left &&
    dragged.top < target.bottom &&
    dragged.bottom > target.top
  );
};
//#endregion

//#region Resultado
useEffect(() => {
  var sequence = []
  if (exibirFeeback) {
    sequence = [
      resultado ? [fundoRef.current, { background: "#46A302" }, { duration: 0.3 }] : [fundoRef.current, { background: "#C53456" }, { duration: 0.3 }],
      [conteudoRef.current, { opacity: 0 }, { duration: 0.3 }],
      [arrowRef.current, { opacity: 0 }, { duration: 0.3 }],
      [feedbackRef.current, { opacity: 1, pointerEvents: 'all' }, { duration: 0.3 }]
    ];
  }
  setTimeout(() => {
    animate(sequence);
    console.log(arrowRef)
  }, 600);
}, [resultado]); // O efeito depende da mudança de resultado
//#endregion

  return (
    <motion.div
      transition={{ duration: 0.5 }} // Duração da animação
      className='min-h-screen relative transition-all duration-500'>
      {/* Fundo colorido */}
      <div className="fixed h-screen w-screen top-0 right-0 left-0 bottom-0 bg-gradient-to-b from-gradienteVerdeStart to-gradienteVerdeEnd -z-50"/>
      {/* Fundo colorido do feedback */}
      <motion.div className="h-screen w-screen fixed top-0 bottom-0 left-0 right-0" ref={fundoRef} />
      {/* Fundo listrado */}
      <div
        className="fixed h-screen w-screen top-0 right-0 left-0 bottom-0"
        style={{
          backgroundImage: 'url(/padrao.svg)',
          backgroundRepeat: 'repeat',
          backgroundSize: '40px',
          opacity: 0.3
        }}
      />
      <div className="min-h-screen flex items-center justify-center relative">
          <div className='flex flex-col items-center container px-4 lg:px-40 relative w-full h-full'>
            {/* Conteúdo do jogo */}
            <motion.div
              ref={conteudoRef}
              initial={{ opacity: 0 }} // Opacidade inicial
              animate={{ opacity: 1 }} // Opacidade final
              exit={{ opacity: 0 }} // Opacidade ao sair
              transition={{ duration: 0.5 }} // Duração da animação
              className="text-2xl font-bold w-full h-full flex flex-col items-center">
              
              {/* Card da pontuação */}
              <Pontuacao pontuacao={acertos} />

              {/* Card do Enunciado */}
              <div className="relative" ref={enunciadoRef}>
                <CardEnunciado imageSrc={questaoAtual.imagem} texto={questaoAtual.enunciado} />
              </div>

              {/* Alternativas */}
              {questaoAtual?.alternativas && (
                <div className="flex flex-col lg:grid lg:grid-rows-1 lg:grid-cols-2 gap-6 lg:gap-24 mt-10 lg:mt-24 w-full mb-8 px-4 lg:px-10 lg:mb-4">
                  {questaoAtual.alternativas.map((alt, index) => (
                    <Draggable
                      position={position}
                      onStart={() => !isInteracaoTravada}
                      onStop={() => { onDrop(index === 0 ? alternativa1Ref : alternativa2Ref) }}
                      allowMobileScroll
                      key={`drag_${alt.id}`}
                    >
                      <div
                        key={alt.id}
                        id={alt.id}
                        ref={index === 0 ? alternativa1Ref : alternativa2Ref}
                        className={`cursor-move z-50`}
                      >
                        <CardAlternativa texto={alt.conteudo} />
                      </div>
                    </Draggable>
                  ))}
                </div>
              )}
              
            </motion.div>

            {/* Componente de feedback com animação */}
            {exibirFeeback && (
              <Feedback
                ref={feedbackRef}
                isErro={!resultado}
                imageSrc={questaoAtual.imagem}
                tag={questaoAtual.resultado.textoTag}
                info={questaoAtual.resultado.conteudo}
                onButtonClick={abrirProximaPergunta}
              />
            )}
          </div>
      </div>
    </motion.div>
  );
};

export default Jogo;