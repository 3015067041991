import { create } from 'zustand';

const useStore = create((set) => ({
  email: '',
  questoes: [],
  perguntas: [],
  perguntasSessao: [],
  acertos: 0,
  isTotem: false,
  isCAS: false,

  // Função para atualizar o e-mail
  setEmail: (email) => set({ email }),

  // Função para atualizar o e-mail
  setIsTotem: (isTotem) => set({ isTotem }),

  // Função para definir as questões
  setQuestoes: (questoes) => {
    set({ questoes })
  },
  setPerguntas: (perguntas) => set({ perguntas }),
  setPerguntasSessao: (perguntasSessao) => set({ perguntasSessao }),

  // Função para atualizar os acertos
  setAcertos: (acertos) => set({ acertos }),

  setIsCAS: (isCAS) => set({ isCAS }),

  // Exemplo de função para resetar o estado
  reset: () => set({ email: '', perguntasSessao: [], acertos: 0 }),
}));

export default useStore;
