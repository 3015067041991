import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Importar os componentes das páginas
import Home from "./pages/Home";
import Cadastro from './pages/Cadastro';
import Tutorial from "./pages/Tutorial";
import Jogo from "./pages/Jogo";
import ResultadoFinal from "./pages/ResultadoFinal";
import Teste from './pages/Teste';

function App() {
  const [semanaAtiva, setSemanaAtiva] = useState(null);

  useEffect(() => {
    setSemanaAtiva(checkSemanaAtiva("2024-11-02"))
  }, []);

  const checkSemanaAtiva = (date) => {
    const dataComparacao = new Date(date);
    const dataAtual = new Date();
    return dataAtual < dataComparacao
  };

  return (
    <Router>
      {
        semanaAtiva &&
        <Routes>
          {/* Rota inicial */}
          <Route index path="/" element={<Home />} />
          <Route path="/totem" element={<Home />} />
          
          {/* Rota de identificação/cadastro */}
          <Route path="/cadastro/*" element={<Cadastro />} />

          {/* Rota do tutorial com subpáginas */}
          <Route path="/tutorial/*" element={<Tutorial />} />

          {/* Rota do jogo */}
          <Route path="/jogo/*" element={<Jogo />} />

          {/* Rota do resultado */}
          <Route path="/resultadoFinal/*" element={<ResultadoFinal />} />

          {/* Rota de testes */}
          <Route path="/testex/*" element={<Teste />} />
          
          <Route path="*" element={<Home />} />
        </Routes>
      }
    </Router>
  );
}

export default App;
