import React, { useRef, useEffect, useState } from 'react';

const Pontuacao = ({ pontuacao }) => {
  return (
    <div className='lg:flex lg:flex-col grid grid-cols-2 grid-rows-1 fixed top-2 shadow-lg lg:shadow-none lg:items-center lg:my-6 lg:relative lg:w-auto z-20 rounded-full lg:rounded-none bg-fundoWhiteAcertos lg:bg-transparent place-items-center'>
        <p className='bg-fundoVerdeAcertos rounded-full lg:py-1 lg:px-3 text-white text-center lg:-mb-4 z-20 text-sm py-2 px-4'>Acertos</p>
        <div className="grid lg:grid-cols-2 lg:gap-4 lg:px-4 lg:pt-6 lg:pb-3 py-2 rounded-3xl bg-fundoWhiteAcertos bg-contain place-items-center">
            <img 
                src={"/ic_acertos.png"}
                alt="Acertos"
                className="lg:w-8 mix-blend-multiply -mt-2 hidden lg:block"
                />
            <p className='font-exo text-sm lg:text-base'>{pontuacao}</p>
        </div>
    </div>
  );
};

export default Pontuacao;
