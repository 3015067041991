import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Botao from '../../componentes/Botao';
import useStore from '../../store/useStore';
import { isEmptyOrWhitespace } from '../../functions';
import { encerrarSessao } from '../../services/apiService';

const ResultadoFinal = () => {
  const navigate = useNavigate();
  const { email, isTotem, isCAS, reset } = useStore();
  const [deveGanharVoucher, setDeveGanharVoucher ] = useState(null);
  
  const handleReiniciar = () => {
    // Lógica para reiniciar o jogo
    var isTotemReset = isTotem
    reset();
    setTimeout(() => {
      if(isTotemReset) navigate(`/totem`)
      else navigate(`/`)
    }, 500)
  };

  const chamarEncerrarSessao = async () => {
    const response = await encerrarSessao(email, isTotem);
    // Verifica se a resposta contém o campo ganhaVoucher e define o estado
    if (response?.ganhaVoucher !== undefined) {
      setDeveGanharVoucher(response.ganhaVoucher);
    }
  }

  useEffect(() => {
    if(isEmptyOrWhitespace(email)) navigate(`/`);
    else chamarEncerrarSessao()
  }, [email, isTotem, navigate]);

  return (
    <div className="min-h-screen bg-white flex items-center justify-center">
      <div className='w-full lg:max-w-5xl p-4 mt-10 lg:mt-0'>
        <h1 className="text-center text-4xl md:text-5xl mb-6 text-verde_titulos font-exo2 font-semibold leading-10 lg:leading-14">Parabéns! Obrigado(a) por participar.</h1>
        <p className="text-center mb-8 font-exo2 text-xl lg:text-2xl text-black">
          {
            deveGanharVoucher == null ?
              <p>Carregando...</p>
            : (
              isTotem ?
              (deveGanharVoucher ?
                <>
                  <p>Retire seu voucher com nosso promotor e prestigie nossos associados utilizando este voucher em suas compras na Feira do Cooperativismo.</p>
                  <p>Um material personalizado foi enviado para o seu e-mail.</p>
                  <p>Confira e aprenda mais sobre os 7 príncipios do cooperativismo.</p>
                </>
                : <>
                  <p>Um material personalizado foi enviado para o seu e-mail.</p>
                  <p>Confira e aprenda mais sobre os 7 príncipios do cooperativismo.</p>
                </>
              ) : isCAS ?
                <p>Você acaba de receber em seu e-mail um voucher para utilização no Shopping Sicredi e um material personalizado com mais informações sobre os 7 príncipios do cooperativismo.</p>
                : <>
                  <p>Um material personalizado foi enviado para o seu e-mail.</p>
                  <p>Confira e aprenda mais sobre os 7 príncipios do cooperativismo.</p>
                </>
            )
          }
        </p>

        {/* Imagem (substitua o src pela sua imagem) */}
        <img
          src="/imagem-06.png" // Placeholder, substitua pela URL da sua imagem
          alt="Voucher"
          className="mx-auto mb-6"
        />

        {/* Botão para reiniciar */}
        <div className="text-center">
          <Botao texto="Reiniciar game" onClick={handleReiniciar} className="mt-4" />
        </div>
      </div>
    </div>
  );
};

export default ResultadoFinal;
