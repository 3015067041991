import React, { useEffect } from 'react';
import Botao from '../../componentes/Botao';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa o useNavigate
import useStore from '../../store/useStore';
import { remoteConfig } from '../../firebase/firebaseConfig.js';
import { fetchAndActivate, getValue } from 'firebase/remote-config';

function Home() {
  const navigate = useNavigate(); // Hook para navegação
  const { setEmail: storeSetEmail, setIsTotem, setPerguntas, perguntas } = useStore();
  const location = useLocation();

  const handleClick = () => {
    // Obtenha o caminho atual
    const currentPath = location.pathname;
    storeSetEmail("")
    setIsTotem(currentPath.includes("totem"))
    navigate('/cadastro'); // Navega para a página do cadastro
  };

  const fetchRemoteConfig = async () => {
    try {
      await fetchAndActivate(remoteConfig);

      // Buscando conteúdo do Remote Config
      const questoesFirebase = JSON.parse(getValue(remoteConfig, 'questoes').asString());
      setPerguntas(questoesFirebase);
    } catch (error) { console.error('Erro ao buscar Remote Config:', error); }
  };

  fetchRemoteConfig();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white">
      {/* Logo centralizada */}
      <div className="lg:mb-16 mb-6">
        <img src="/logo.svg" alt="Logo" className="h-16 lg:h-24 mx-auto mt-6 lg:mt-0" />
      </div>

      {/* Container para as colunas */}
      <div className="flex flex-col lg:flex-row items-center lg:mx-36 container lg:w-auto px-12">
        {/* Coluna da imagem */}
        <div className="flex justify-end lg:w-128">
          <img
            src="/imagem-01.png"
            alt="Descrição da imagem"
          />
        </div>

        {/* Coluna do texto */}
        <div className="text-center mx-10 container lg:w-128 mb-10 lg:mb-0">
          <h1 className="text-4xl md:text-5xl mb-6 lg:mb-10 text-verde_titulos font-exo2 font-semibold leading-10 lg:leading-14">Bem-vindo ao jogo Conectando o Cooperativismo</h1>
          <p className="text-2xl lg:text-3xl mb-8 lg:mb-12 font-exo2 leading-7">
            Jogue e descubra sobre como aplicamos os princípios do cooperativismo no nosso dia a dia.
          </p>
          <Botao isDisabled={perguntas == []} texto="Começar" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
}

export default Home;
