import axios from 'axios';

// Configurações globais da API
const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:8080',
  baseURL: process.env.REACT_APP_API_URL || 'https://photocam.com.br:8443',
  timeout: 10000, // Timeout opcional
  headers: {
    'Content-Type': 'application/json',
  },
});

const endpointRegistrarEmail = "/api/registrarEmail";
const endpointEncerrarSessao = "/api/encerrarSessao";

export const registrarEmail = async (email) => {
    try {
        var corpo = { "email": email }
        const response = await api.post(endpointRegistrarEmail, corpo);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Erro no envio de dados');
    }
};

export const encerrarSessao = async (email, isTotem) => {
    try {
        var corpo = { "email": email, "isTotem": isTotem }
        const response = await api.post(endpointEncerrarSessao, corpo);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Erro na requisição');
    }
};
