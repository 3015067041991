import React from 'react';

const Botao = ({ texto, isDisabled = false, onClick, type = 'button', className = '' }) => {
	return (
		<button
			disabled={isDisabled}
			type={type} 
			onClick={onClick} 
			className={`bg-verde_primary
				text-white
				py-4 px-24
				rounded-xl
				shadow-botao transition-all
				uppercase
				font-exo font-bold
				${isDisabled ? "grayscale cursor-not-allowed" : "hover:bg-verde_titulos"}
				${className}`}
			>
				{texto}
		</button>
	);
}

export default Botao;