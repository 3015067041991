export const isEmptyOrWhitespace = (str) => {
    return !str || str.trim().length === 0;
}

export const embaralharESelecionar = (lista, numeroDeElementos) => {
    // Embaralha a lista
    const listaEmbaralhada = lista
      .map((valor) => ({ valor, ordem: Math.random() }))
      .sort((a, b) => a.ordem - b.ordem)
      .map(({ valor }) => valor);
  
    // Retorna o número solicitado de elementos
    return listaEmbaralhada.slice(0, numeroDeElementos);
};