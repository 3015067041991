import React, { useEffect, useState } from 'react';
import Botao from '../../componentes/Botao';
import { useNavigate } from 'react-router-dom'; // Importa o useNavigate
import useStore from '../../store/useStore';
import { embaralharESelecionar, isEmptyOrWhitespace } from '../../functions';
import { registrarEmail } from '../../services/apiService';

function Cadastro () {
  const navigate = useNavigate(); // Hook para navegação
  const [emailValor, setEmailValor ] = useState('');
  const { setEmail: storeSetEmail, perguntas, isTotem, setPerguntasSessao, setIsCAS } = useStore();

  const dominio = "@sicredi.com.br"
  
  // Função para lidar com a alteração do valor do input
  const onInputChange = (e) => {
    setEmailValor(e.target.value); // Atualiza o valor no estado local
  };

  const handleClick = async () => {
    var listaPerguntasSessao = embaralharESelecionar(perguntas, isTotem ? 3 : 7)
    setPerguntasSessao(listaPerguntasSessao)
    console.log('Continuar');
    storeSetEmail(`${emailValor}${dominio}`)
    var resposta = await registrarEmail(`${emailValor}${dominio}`)
    console.log(`Resposta: ${JSON.stringify(resposta)}`)
    console.log(`isCAS: ${resposta.isCAS}`)
    setIsCAS(resposta.isCAS)
    navigate('/tutorial'); // Navega para a página do tutorial
  };

  useEffect(() => {
    if(perguntas == null) {
      console.log("Falha ao recuperar perguntas")
      navigate(`/`);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <div className='flex flex-col items-center justify-center container lg:w-128'>
        {/* Pequena imagem no topo */}
        <img src="/imagem-02.png" alt="Logo" className="h-56 mb-4" />
        
        {/* Título */}
        <h1 className="text-4xl md:text-5xl mb-6 text-verde_titulos font-exo2 font-semibold leading-10 lg:leading-14">Identificação</h1>
        
        {/* Texto explicativo */}
        <p className="text-center mb-8 font-exo2 text-xl lg:text-2xl text-black">
          Antes de começar, insira seu email para participar dessa experiência.
        </p>
        
        {/* Campo de E-mail */}
        <div className="w-80 mb-10 relative">
          <label htmlFor="email" className="block text-left text-willow mb-2 font-exo font-bold uppercase">E-mail</label>
          <input
            id="email"
            type="email"
            placeholder="insira seu e-mail"
            className="w-full p-3 border border-gray-300 rounded-xl mb-6 font-exo font-bold text-willow px-3 outline-offset-0 focus-visible:outline-verde_titulos outline-none focus-visible:ring-verde_titulos"
            onChange={onInputChange}
          />
          {/* Sufixo sempre visível, posicionado à direita */}
          <span className="absolute right-3 top-1/2 transform -translate-y-2 font-exo text-willow font-bold">
            {dominio}
          </span>
        </div>

        
        {/* Botão Continuar */}
        <Botao texto="Continuar" isDisabled={isEmptyOrWhitespace(emailValor)} onClick={handleClick} />
      </div>
    </div>
  );
}

export default Cadastro ;
