import React from 'react';

const CardAlternativa = ({ texto }) => {
  return (
    <div className="bg-white rounded-3xl shadow-cardExterno relative w-full lg:h-full">
      <div className="h-full rounded-3xl shadow-cardInterno px-4 py-6 lg:px-8 lg:py-8 relative flex flex-col justify-center">
        <p className='font-exo2 font-medium text-base lg:text-xl leading-5 lg:leading-6'>{texto}</p>
      </div>
    </div>
  );
};

export default CardAlternativa