import React, { useRef, useEffect, useState } from 'react';

const CardEnunciado = ({ ref, imageSrc, texto }) => {
  const cardRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.clientHeight;
      setImageHeight(cardHeight); // Define a altura da imagem para a mesma do card
    }
  }, [texto]);

  return (
    <div className="flex flex-col lg:flex-row items-center mt-8 lg:mt-0" ref={ref}>
      {/* Container da imagem redonda */}
      <div
        className={`relative overflow-hidden flex-shrink-0 border-8 z-10 max-w-28 max-h-28 lg:max-w-none lg:max-h-none box-content bg-white border-gradienteVerdeEnd rounded-full lg:-mr-36`}
        style={{ width: `${imageHeight}px`, height: `${imageHeight}px` }} // Aplica a altura do card à imagem
      >
        <img 
          src={imageSrc}
          alt="Enunciado"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Container do texto */}
      <div ref={cardRef} className="bg-white rounded-3xl shadow-cardExterno relative h-full -mt-8 lg:mt-0 lg:ml-4 flex flex-1">
        {/* Card Interno */}
        <div className="rounded-3xl shadow-cardInterno flex flex-col justify-center h-full w-full py-4 pl-4 pr-4 lg:py-10 lg:pl-40 lg:pr-10">
          <h2 className="text-lg lg:text-xl font-bold mb-4 font-exo2">Situação:</h2>
          <h2 className="text-lg lg:text-xl font-semibold font-exo2 leading-5 lg:leading-6">{texto}</h2>
        </div>
      </div>
    </div>
  );
};

export default CardEnunciado;
