// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = {
  apiKey: "AIzaSyBaO04jpCEG6up2aQpYqk6GIbLD1ILf1-c",
  authDomain: "semanacooperativismo2024.firebaseapp.com",
  projectId: "semanacooperativismo2024",
  storageBucket: "semanacooperativismo2024.appspot.com",
  messagingSenderId: "697005273054",
  appId: "1:697005273054:web:191eaa8197da186530f74f",
  measurementId: "G-BS903MHJRF"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);

// remoteConfig.settings = {
//   minimumFetchIntervalMillis: 36000,
// };

export { remoteConfig, analytics };