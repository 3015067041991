import React, { useRef, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import useStore from '../../store/useStore';
import { useEffect } from 'react';
import { isEmptyOrWhitespace } from '../../functions';
import { motion, animate, delay } from 'framer-motion'; // Para animações
import Botao from '../../componentes/Botao';

const Tutorial = () => {
  const { email, perguntas } = useStore();
  const navigate = useNavigate();
  const paginaRef = useRef(null);
  const [indicepaginaAtual, setIndicePaginaAtual] = useState(0);

  const paginas = [
    {
      "imagem": "/tutoriais/tutorial-01.png",
      "conteudo": "Para cada princípio do cooperativismo, apresentamos uma situação e você deve arrastar a alternativa que ilustra corretamente a aplicação daquele princípio."
    },{
      "imagem": "/tutoriais/tutorial-02.png",
      "conteudo": "Ao participar você receberá um infográfico por e-mail, que lhe auxiliará a aplicar os princípios do cooperativismo no seu dia a dia."
    }
  ]

  // Funções para navegar entre subpáginas
  const avancar = () => {
    if (indicepaginaAtual < paginas.length - 1) {
      animarSaidaProximo()
      setTimeout(() => {
        setIndicePaginaAtual(indicepaginaAtual + 1)
        animarEntradaProximo()
      }, 800)
    } else {
      navigate(`/jogo`)
    }
  };

  const animarSaidaProximo = () => {
    const sequence = [
      [ paginaRef.current, { opacity: 0, x: -200 }, { duration: 0.3 } ],
      [ paginaRef.current, { opacity: 0, x: 200 }, { duration: 0, delay: 0.3 } ],
    ]
    animate(sequence)
  }

  const animarEntradaProximo = () => {
    const sequence = [
      [ paginaRef.current, { opacity: 1, x: 0 }, { duration: 0.3 } ],
    ]
    animate(sequence)
  }

  const animarSaidaAnterior = () => {
    const sequence = [
      [ paginaRef.current, { opacity: 0, x: 200 }, { duration: 0.3 } ],
      [ paginaRef.current, { opacity: 0, x: -200 }, { duration: 0, delay: 0.3 } ],
    ]
    animate(sequence)
  }

  const animarEntradaAnterior = () => {
    const sequence = [
      [ paginaRef.current, { opacity: 1, x: 0 }, { duration: 0.3 } ],
    ]
    animate(sequence)
  }
  
  const voltar = () => {
    if (indicepaginaAtual == 0) {
      navigate(`/cadastro`);
    } else {
      animarSaidaAnterior()
      setTimeout(() => {
        setIndicePaginaAtual(indicepaginaAtual - 1)
        animarEntradaAnterior()
      }, 800)
    }
  };

  useEffect(() => {
    console.log(`email: ${email}`)
    if(isEmptyOrWhitespace(email)) {
      console.log("Você deve se cadastrar antes")
      navigate(`/`);
    }
  }, []);

  return (
    <div className="min-h-screen bg-white flex items-center justify-center">
      <button className="fixed top-4 left-4 z-10 border-2 border-cinza_claro rounded-full h-16 w-16 flex place-items-center justify-center" onClick={voltar}>
        <img
          className='w-6 h-6'
          src={`/back.svg`}
          alt={`Voltar`}
        />
      </button>
      <motion.div
        className="w-full max-w-2xl p-4 mt-10 lg:mt-0"
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        ref={paginaRef}
      >
        {/* Conteúdo da Subpágina */}
        <h1 className="text-center text-4xl md:text-5xl mb-6 text-verde_titulos font-exo2 font-semibold leading-10 lg:leading-14">Como o jogo funciona</h1>
        <p className="text-center mb-8 font-exo2 text-xl lg:text-2xl text-black">
          {paginas[indicepaginaAtual].conteudo}
        </p>

        {/* Imagem */}
        <img
          src={paginas[indicepaginaAtual].imagem}
          alt={`Imagem do tutorial ${indicepaginaAtual}`}
          className="mx-auto mb-6"
        />

        {/* Botão Próximo */}
        <div className="text-center">
          <Botao
            texto="Próximo"
            onClick={avancar} 
            />
        </div>
      </motion.div>
    </div>
  );
};

export default Tutorial;
