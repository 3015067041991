import React, { useRef, useState } from "react";
import Draggable from "react-draggable";

const Teste = () => {
  const [foiSoltoEmCima, setFoiSoltoEmCima] = useState(false);
  const dropAreaRef = useRef(null);
  const dragRef = useRef(null);

  const handleDrop = () => {
    const dropArea = dropAreaRef.current.getBoundingClientRect();
    const dragElement = dragRef.current.getBoundingClientRect();

    if (isDroppedOn(dropArea, dragElement)) {
      setFoiSoltoEmCima(true);
      console.log("O elemento foi solto na área de destino.");
    } else {
      setFoiSoltoEmCima(false);
      console.log("O elemento foi solto fora da área de destino.");
    }
  };

  const isDroppedOn = (dropArea, dragElement) => {
    return (
      dragElement.left < dropArea.right &&
      dragElement.right > dropArea.left &&
      dragElement.top < dropArea.bottom &&
      dragElement.bottom > dropArea.top
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Draggable nodeRef={dragRef} onStop={handleDrop}>
        <div
          ref={dragRef}
          className="bg-blue-500 text-white p-4 cursor-move"
        >
          Arraste-me
        </div>
      </Draggable>

      <div
        ref={dropAreaRef}
        className="w-64 h-64 mt-10 bg-gray-300 flex items-center justify-center"
      >
        {foiSoltoEmCima ? (
          <p className="text-green-600">Elemento Solto!</p>
        ) : (
          <p>Solte o elemento aqui</p>
        )}
      </div>
    </div>
  );
};

export default Teste;
